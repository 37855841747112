<template>
  <v-container>
    <v-progress-linear
      class="mt-2"
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="indigo accent-4"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <h1>
        New visit for
        <a :href="`/home/manage-patient/${patientId}`">{{ name }}</a>
      </h1>
    </v-row>
    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="mb-3">
          <v-card-text>
            <div>Age: {{ age }}</div>
            <div>Gender: {{ gender }}</div>
            <div>Species: {{ species }}</div>
            <div>Breed: {{ breed }}</div>
            <v-form class="mt-3">
              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Weight
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group v-model="weightSelection" row>
                        <v-radio label="Kg" value="1"></v-radio>
                        <v-radio label="Lb" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col col="2" md="2">
                      <v-text-field
                        aria-label="enter weight in killograms or pounds"
                        type="number"
                        min="1"
                        max="300"
                        v-model="weight"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-if="model"
                    v-model="ultro_type"
                    item-text="name"
                    item-value="id"
                    :items="model.lookups.ultro_types"
                    label="Type of Ultrasound"
                    @change="onUltroTypeChange"
                  >
                    <template #label>
                      <div>
                        Type of Ultrasound
                        <span class="required"
                          ><strong>(required)</strong></span
                        >
                      </div>
                    </template>
                  </v-select>
                  <v-text-field
                    v-model="ultro_other_text"
                    v-if="showUltroOther"
                    hint="Provide other information"
                    label="Other information"
                  >
                    <template #label>
                      Other information
                      <span class="required"><strong>(required)</strong></span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <UltrosoundTypeTree
                ref="ultroMain"
                v-if="ultro_type != 5 && ultro_type != 1 && ultro_type != -1"
                :ultroTypeId="ultro_type"
                :lookups="model == null ? null : model.lookups"
                @childIsReady="onChildReady"
              ></UltrosoundTypeTree>
              <UltrosoundTypeTreeBicavity
                ref="ultroBicavity"
                v-if="ultro_type == 5"
                :lookups="model == null ? null : model.lookups"
                @childIsReady="onChildReady"
              ></UltrosoundTypeTreeBicavity>
              <v-textarea
                auto-grow
                hint="enter visit history, minimum 3 characters"
                label="History"
                v-model="reason"
              >
                <template #label
                  >History
                  <span class="required"
                    ><strong>(required)</strong></span
                  ></template
                >
              </v-textarea>
              <v-textarea
                auto-grow
                hint="enter patient's medications, minimum 3 characters"
                label="Medications"
                v-model="medication"
                ><template #label
                  >Medications
                  <span class="required"
                    ><strong>(required)</strong></span
                  ></template
                ></v-textarea
              >
              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Are you adding x-rays for interpretation (extra charge)
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group v-model="xray" row>
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <span v-if="xray == 1"
                    >Send x-rays via email to houndultrasound@gmail.com</span
                  >
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <label style="font-size: 1.2em"
                    >Are you submitting Lab results or any other documents
                    <span class="required"
                      ><strong>(required)</strong></span
                    ></label
                  >
                  <v-row no-gutters>
                    <v-col col="3" md="3">
                      <v-radio-group v-model="labResults" row>
                        <v-radio label="Yes" value="1"></v-radio>
                        <v-radio label="No" value="2"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <hr />

              <v-alert color="orange" dense v-show="error_invalidFileSize"
                >Upload file limit is 50 Mb. Please select a file(s) with
                smaller size.</v-alert
              >
              <v-alert color="orange" dense v-show="error_tooManyFiles">{{
                max_num_files
              }}</v-alert>

              <v-alert
                color="orange"
                dense
                v-show="error_duplicates_selected"
                >{{ duplicates_message }}</v-alert
              >
              <CustomUploader
                @selected-files="onFileSelectionChanged"
              ></CustomUploader>
              <v-card-actions justify="end" v-show="!$store.state.isUserAdmin">
                <v-spacer> </v-spacer>
                <v-btn
                  :disabled="!isFormValid"
                  small
                  color="primary"
                  @click="onProceed"
                >
                  Proceed
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog_review" persistent max-width="400">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>Review visit information</v-card-title>
        <v-card-text>
          <div>
            <strong>Weight:</strong> {{ weight }}
            {{ weightSelection == 1 ? "kg" : "lb" }}
          </div>
          <div>
            <strong>Type of Ultrasound:</strong> {{ ultro_formatted }}
            {{ ultro_type == "1" ? `(${ultro_other_text})` : "" }}
          </div>
          <div>
            <strong>History:</strong>
            {{ reason.length > 45 ? reason.substring(0, 45) + "..." : reason }}
          </div>
          <div>
            <strong>Medications:</strong>
            {{
              medication.length > 40
                ? medication.substring(0, 40) + "..."
                : medication
            }}
          </div>
          <div>
            <strong>Are you adding x-rays for interpretation:</strong>
            {{ xray == 1 ? "Yes" : "No" }}
          </div>
          <div>
            <strong
              >Are you submitting Lab results or any other documents:</strong
            >
            {{ labResults == 1 ? "Yes" : "No" }}
          </div>
          <div>
            {{
              `${
                files
                  ? files.length > 0
                    ? files.length + " files selected for upload"
                    : ""
                  : ""
              }`
            }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" text @click="dialog_review = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="onSubmit"> Submit </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import cookieWrapper from "../auth/cookieWrapper";
import UltrosoundTypeTree from "./sub/UltrasoundTypeTree.vue";
import UltrosoundTypeTreeBicavity from "./sub/UltrosundTypeTreeBicavity.vue";
import CustomUploader from "./sub/CustomUploader.vue";

export default {
  name: "CreatePatientForm",
  title: "Create new patient visit",
  props: {
    patientId: String,
  },
  components: {
    UltrosoundTypeTree,
    UltrosoundTypeTreeBicavity,
    CustomUploader,
  },
  data() {
    return {
      loading: false,
      overlay: false,
      dialog_review: false,
      name: "",
      age: "",
      gender: "",
      species: "",
      breed: "",
      weight: "",
      ultro_type: -1,
      weightSelection: -1,
      reason: "",
      medication: "",
      model: null,
      proceed: false,
      xray: "",
      labResults: "",
      counter: 0, //this property only exists to implement validation hack! https://stackoverflow.com/questions/48700142/vue-js-force-computed-properties-to-recompute
      files: undefined,
      error_tooManyFiles: false,
      error_invalidFileSize: false,
      error_duplicates_selected: false,
      ultro_other_text: "",
      max_num_files: `Maximum number of files to upload is ${process.env.VUE_APP_ALLOWED_MAX_FILES}`,
      duplicates_message: `Duplicate file(s) selection is not allowed!`,
    };
  },
  validations: {
    weight: {
      required,
      maxLenght: maxLength(5),
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },
    weightSelection: {
      required,
      mustBeSelected: function (value) {
        if (value != -1) {
          return true;
        }

        return false;
      },
    },
    reason: {
      required,
      minLength: minLength(3),
    },
    medication: {
      required,
      minLength: minLength(3),
    },
    xray: {
      required,
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },
    labResults: {
      required,
      mustBeSelected: function (value) {
        if (value != "") {
          return true;
        }

        return false;
      },
    },

    ultro_type: {
      required,
      mustBeSelected: function (value) {
        if (value != "" && value != -1) {
          return true;
        }

        return false;
      },
    },
    ultro_other_text: {
      conditionalSelection: function (value) {
        if (this.ultro_type == "1") {
          if (value) {
            return true;
          } else {
            return false;
          }
        }

        return true;
      },
    },
  },
  async mounted() {
    const self = this;
    const token = cookieWrapper.getToken(self);

    const config = {
      method: "get",
      url: `/api/patient-form/model/new/${self.patientId}?secret_token=${token}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await axios(config);
    self.model = result.data;
    console.log(result.data);

    //patient info
    self.name = `${self.model.patient.lastName}, ${self.model.patient.name}`;
    self.species = self.model.patient.species.name;
    self.age = self.model.patient.age_formatted;
    self.gender = self.model.patient.gender.name;
    self.breed = self.model.patient.breed;
  },
  methods: {
    async onSubmit() {
      const self = this;

      const token = cookieWrapper.getToken(self);

      self.overlay = true;

      let payload = {
        clinicId: self.$store.state.loggedInClinicId,
        patientId: self.patientId,
        medications: self.medication,
        reason: self.reason,
        weight: {
          weightKg: self.weightSelection == 1 ? self.weight : 0,
          weightLb: self.weightSelection == 2 ? self.weight : 0,
        },
        ultro_type: self.model.lookups.ultro_types.filter((item) => {
          return item.id == self.ultro_type;
        })[0],
        xRayForInterpretation: self.xray == "1" ? true : false,
        labResults: self.labResults == "1" ? true : false,
        loggedInUser: self.$store.state.loggedInUser,
        ultro_other_text: self.ultro_other_text,
      };

      switch (Number(self.ultro_type)) {
        case 2:
        case 3:
        case 4:
          payload.interpretation_type =
            self.model.lookups.interpretation_types.filter((item) => {
              return item.id == this.$refs.ultroMain.interpretationTypeId;
            })[0];
          payload.service_type = self.model.lookups.service_types.filter(
            (item) => {
              return item.id == this.$refs.ultroMain.serviceTypeId;
            }
          )[0];
          break;
        case 5:
          {
            const selectedTypeId = this.$refs.ultroBicavity.typeOfUltraId;

            if (selectedTypeId == 1) {
              payload.bicavity = {
                abdominal_cardiac: {
                  abdominal: {
                    interpretation_type:
                      self.model.lookups.interpretation_types.filter((item) => {
                        return (
                          item.id ==
                          this.$refs.ultroBicavity.interpretationTypeIdAbdominal
                        );
                      })[0],
                    service_type: self.model.lookups.service_types.filter(
                      (item) => {
                        return (
                          item.id ==
                          this.$refs.ultroBicavity.serviceTypeIdAbdominal
                        );
                      }
                    )[0],
                  },
                  cardiac: {
                    interpretation_type:
                      self.model.lookups.interpretation_types.filter((item) => {
                        return (
                          item.id ==
                          this.$refs.ultroBicavity.interpretationTypeIdCardiac
                        );
                      })[0],
                    service_type: self.model.lookups.service_types.filter(
                      (item) => {
                        return (
                          item.id ==
                          this.$refs.ultroBicavity.serviceTypeIdCardiac
                        );
                      }
                    )[0],
                  },
                },
              };
            } else {
              payload.bicavity = {
                other: {
                  other_text: this.$refs.ultroBicavity.otherUltraTypeText,
                },
              };
            }
          }
          break;
        default:
          break;
      }

      let config = {
        method: "put",
        url: `/api/patient-form/create?secret_token=${token}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: payload,
      };

      axios(config)
        .then((response) => {
          //id of newly created form
          const formId = response.data;

          //upload files
          let formData = new FormData();
          let counter = 1;
          self.files.forEach((element) => {
            formData.append(`file${counter}`, element);
            formData.append(
              `file_name${counter}`,
              encodeURIComponent(element.name)
            );
            counter++;
          });

          formData.append("formId", formId);
          formData.append("loggedInUser", self.$store.state.loggedInUser);
          formData.append("file_count", self.files.length);

          config = {
            method: "post",
            url: `/api/patient-form/upload-files?secret_token=${token}`,
            headers: {
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          };

          axios(config)
            .then((response) => {
              console.log(response);
              self.overlay = false;
            })
            .catch((error) => {
              console.log(error);
              self.overlay = false;
            });
        })
        .catch((error) => {
          console.log(error);
          self.overlay = false;
        });

      self.$router.push("/home/patients");
    },

    onProceed() {
      this.dialog_review = true;
    },
    onFileSelected() {
      this.error_tooManyFiles = false;
      this.error_invalidFileSize = false;
      this.error_duplicates_selected = false;

      const count = this.files.length;
      if (count > Number(process.env.VUE_APP_ALLOWED_MAX_FILES)) {
        this.error_tooManyFiles = true;
      }

      //let fileSize = this.files.reduce((t) => t++);
      let fileSize = 0;
      this.files.map((t) => {
        fileSize += t.size;
      });

      if (fileSize > Number(process.env.VUE_APP_ALLOWED_MAX_UPLOAD_SIZE)) {
        this.error_invalidFileSize = true;
      }

      if (this.checkForDuplicateNames(this.files)) {
        this.error_duplicates_selected = true;
      }
    },

    onUltroTypeChange() {
      if (this.ultro_type != "1") {
        this.ultro_other_text = "";
      }
    },

    onChildReady() {
      //this is a hack, to resolve issue with child control validation!
      //do not remove this
      this.counter++;
    },

    onFileSelectionChanged(files) {
      this.files = files.flat();

      this.onFileSelected();
    },

    checkForDuplicateNames(arr) {
      let names = arr.map((obj) => obj.name);
      let uniqueNames = new Set(names);
      return names.length !== uniqueNames.size;
    },
  },

  computed: {
    allowUpload() {
      if (this.files == undefined || this.files.length == 0) {
        return true;
      }

      if (this.error_invalidFileSize) {
        return false;
      }

      if (this.error_tooManyFiles) {
        return false;
      }

      return true;
    },

    ultro_formatted() {
      return this.model
        ? this.model.lookups.ultro_types.filter((t) => {
            return t.id == this.ultro_type;
          }).length > 0
          ? this.model.lookups.ultro_types.filter((t) => {
              return t.id == this.ultro_type;
            })[0].name
          : ""
        : "";
    },

    showUltroOther() {
      if (this.ultro_type == "1") return true;

      return false;
    },

    isFormValid() {
      this.counter;

      if (this.ultro_type == -1) {
        return false;
      }

      if (this.ultro_type == 1) {
        return this.allowUpload && !this.$v.$invalid;
      } else if (this.ultro_type != 5) {
        return (
          this.allowUpload &&
          !this.$v.$invalid &&
          (this.$refs.ultroMain == undefined
            ? false
            : !this.$refs.ultroMain.$v.$invalid)
        );
      } else {
        return (
          this.allowUpload &&
          !this.$v.$invalid &&
          (this.$refs.ultroBicavity == undefined
            ? false
            : !this.$refs.ultroBicavity.$v.$invalid)
        );
      }
    },
  },
};
</script>

<style scoped>
hr {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
  margin-bottom: 30px;
}
</style>