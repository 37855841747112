<template>
  <v-container>
    <v-row align="center" justify="center">
      <h1 class="mb-3">Fee Schedule</h1>
    </v-row>

    <v-row align="center" justify="center">
      <v-col md="9" sm="5" col="12">
        <v-card class="elevation-8">
          <v-card-title>Please review fee schedule below</v-card-title>
          <v-card-text>
            <!-- <iframe
              class="pdf"
              src="../docs/2024_Updated_Fee_Schedule_Hound_Ultrasound_May_27_2024.pdf"
              width="100%"
              height="1000"
            >
            </iframe> -->
            <v-img :src="source"></v-img>
          </v-card-text>
        </v-card> </v-col
    ></v-row>
  </v-container>
</template>

<script>
export default {
  name: "ContactUs",
  title: "HU Portal - Fee Schedule",

  computed: {
    source() {
      return `/docs/${process.env.VUE_APP_FEE_SCHEDULE_IMG}`;
    },
  },
};
</script>